import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

type ISettingsState = {
  showBottomAd: boolean;
  setShowBottomAd: (showBottomAd: boolean) => void;

  currentProfileId: string | undefined;
  setCurrentProfileId: (currentProfileId: string) => void;

  debugMode: boolean;
  setDebugMode: (debugMode: boolean) => void;
};

export const useSettingsStore = create(
  persist<ISettingsState>(
    (set) => ({
      showBottomAd: true,
      setShowBottomAd: (showBottomAd) => set({ showBottomAd }),
      currentProfileId: undefined,
      setCurrentProfileId: (currentProfileId) => set({ currentProfileId }),
      debugMode: false,
      setDebugMode: (debugMode: boolean) => set({ debugMode }),
    }),
    {
      name: 'user-settings',
      storage: createJSONStorage(() => localStorage),
    },
  ),
);
