import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import styled from 'styled-components';

import { log } from '@/services/log';
import { useSettingsStore } from '@/store/settings.store';

export function BottomAdComponent() {
  const router = useRouter();
  const { showBottomAd, setShowBottomAd } = useSettingsStore();
  const [isOpen, setIsOpen] = useState(false);

  const BOTTOM_AD_DELAY = 7000;
  useEffect(() => {
    const timer = setTimeout(() => {
      if (
        process.env.NEXT_PUBLIC_SHOW_BOTTOM_AD === 'true' &&
        showBottomAd &&
        // only on IOS
        /(iPod|iPhone|iPad)/.test(navigator.userAgent)
      ) {
        log.info(
          'BottomAdComponent: show bottom ad, useragent:',
          navigator.userAgent,
        );
        setIsOpen(true);
      } else {
        log.info(
          'BottomAdComponent: skip bottom ad, useragent:',
          navigator.userAgent,
        );
      }
    }, BOTTOM_AD_DELAY);

    return () => clearTimeout(timer);
  }, []);

  function handleClose() {
    setIsOpen(false);
    setShowBottomAd(false);
  }

  async function handleBlackButtonClick() {
    handleClose();
    await router.push('https://apps.apple.com/app/get-outfit/id6448672920');
  }

  if (isOpen) {
    return (
      <ComponentWrapper onClick={handleClose}>
        <ContentWrapper onClick={(event) => event.stopPropagation()}>
          <Content>
            <LogoAndTextWrapper>
              <Logo>GET OUTFIT</Logo>
              <StyledSpan>
                Enjoyed these picks?<br></br> Then you will love our app!
              </StyledSpan>
            </LogoAndTextWrapper>
            <ButtonsWrapper>
              <StyledWhiteButton onClick={handleClose}>
                STAY ON THE WEB
              </StyledWhiteButton>
              <StyledBlackButton onClick={handleBlackButtonClick}>
                FIND MORE IN THE APP
              </StyledBlackButton>
            </ButtonsWrapper>
          </Content>
        </ContentWrapper>
      </ComponentWrapper>
    );
  }
  // eslint-disable-next-line unicorn/no-null
  return null;
}

const ComponentWrapper = styled.div`
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
`;

const ContentWrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: white;
  box-shadow: 0px -27px 20px 0px rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 200px;
`;

const LogoAndTextWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

const Logo = styled.div`
  width: 70px;
  height: 60px;
  background-color: black;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 6px;
`;

const Content = styled.div`
  margin: 20px;
  padding-top: 20px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 25px;
  gap: 10px;
`;

const StyledSpan = styled.span`
  color: black;
  font-family: 'Fira Mono', monospace;
  font-size: 16px;
  line-height: 16px;
`;

const StyledWhiteButton = styled.button`
  font-family: 'Fira Mono', monospace;
  background-color: white;
  width: 180px;
  color: black;
  border: 1px solid black;
  padding: 10px 20px;
  font-size: 10px;
  cursor: pointer;
  transition:
    background-color 0.2s ease-in-out,
    color 0.2s ease-in-out;

  &:hover {
    background-color: #f0f0f0;
  }
`;

const StyledBlackButton = styled.button`
  font-family: 'Fira Mono', monospace;
  background-color: black;
  width: 180px;
  color: white;
  border: 1px solid black;
  padding: 10px 20px;
  font-size: 10px;
  cursor: pointer;
  transition:
    background-color 0.2s ease-in-out,
    color 0.2s ease-in-out;

  &:hover {
    background-color: #343131;
  }
`;
